<template>
  <div class="container-fluid">
    <h4 class="text-center my-4">Расписание</h4>

    <div class="my-3">
      <router-link to="/schedule/manager" class="ms-2 text-decoration-none">Заполнить расписание</router-link>
    </div>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">

      <TabView>
        <TabPanel v-for="(day, dayIndex) in days" :key="dayIndex" :header="day.name_ru">
          <div class="table-responsive my-2 table-scroll">
            <table class="table table-striped table-bordered">
              <thead>
              <tr>
                <th scope="col" class="min-w120">Время/Аудитория</th>
                <th v-for="(room, roomIndex) in rooms" :key="roomIndex" scope="col" class="min-w300">
                  {{room.name}}
                  <div class="mt-1">
                    Количество мест: {{room.count_of_seats}}
                  </div>
                  <div class="mt-1">
                    Количество компьютеров: {{room.count_of_computers}}
                  </div>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(intervalTime, intervalTimeIndex) in scheduleIntervalTimes" :key="intervalTimeIndex">
                <th scope="row">{{ intervalTime.time }}</th>
                <td v-for="(room, roomIndex) in rooms" :key="roomIndex">

                  <div v-for="(scheduleRow, scheduleRowIndex) in scheduleTable[day.id][intervalTime.id][room.id]"
                       :key="scheduleRowIndex"
                       class="rounded-3 p-2 mb-2 bg-violet text-violet border">
                    <div>
                      {{scheduleRow.education_discipline_name}}
                      ({{scheduleRow.language_name}}) - {{scheduleRow.lastname}} {{scheduleRow.firstname}}
                    </div>
                    <div class="mt-1">
                      <span class="me-2">{{scheduleRow.education_type_name}}</span>
                    </div>
                    <div class="mt-1">
                      Мест: {{scheduleRow.students_count}}
                    </div>
                      <div class="mt-2">
                        <Button icon="pi pi-info-circle" class="p-button-rounded p-button-danger p-button-text"
                                @click="openTeacherLateList(scheduleRow.id,scheduleRow.day_id,scheduleRow.schedule_interval_time_id, roomIndex+1)"/>
                        <Button icon="pi pi-calendar-minus" class="p-button-rounded p-button-danger p-button-text"
                                @click="openNewLate(scheduleRow.id,scheduleRow.day_id,scheduleRow.schedule_interval_time_id, roomIndex+1)"/>
                      </div>

                  </div>

                </td>
              </tr>
              </tbody>
            </table>
          </div>

        </TabPanel>

      </TabView>

    </div>


    <!-- add TeacherLate -->
    <Dialog header="Опоздание преподавателя" :visible="newLate.display" :closable="false" :modal="true"
            :style="{width: '100%', maxWidth: '600px'}">
      <div class="my-3">
        <h5>{{newLate.name}}</h5>

        <div class="form-group row mt-4">
          <label for="late_date" class="col-md-3 col-form-label">Дата и время</label>
          <div class="col-md-9">
            <input id="late_date" type="datetime-local" class="form-control" v-model="newLate.form.date">
          </div>
        </div>

        <div class="form-group row mt-4">
          <label for="late_time" class="col-md-3 col-form-label">Сколько времени опоздал</label>
          <div class="col-md-9">
            <input id="late_time" type="text" class="form-control" v-model="newLate.form.late_time">
          </div>
        </div>

        <div class="form-group row mt-4">
          <label for="reason" class="col-md-3 col-form-label">Причина</label>
          <div class="col-md-9">
            <input id="reason" type="text" class="form-control" v-model="newLate.form.reason">
          </div>
        </div>

      </div>

      <template #footer>
        <Button label="Закрыть" icon="pi pi-times" @click="closeNewLate" class="p-button-text"/>
        <Button label="Сохранить" icon="pi pi-save"
                :disabled="!newLate.form.date||!newLate.form.late_time"
                :loading="newLate.loading"
                @click="postTeacherLate" autofocus/>
      </template>
    </Dialog>
    <!-- end add TeacherLate -->


    <!-- add TeacherLateList -->
    <Dialog header="Список опозданий" v-model:visible="teacherLate.display" :modal="true"
            :style="{width: '100%', maxWidth: '1100px'}">
      <div class="my-3">
        <h5>{{teacherLate.name}}</h5>

        <div class="mt-4">
          <div v-if="teacherLate.list.length">
            <DataTable :value="teacherLate.list" showGridlines stripedRows responsiveLayout="scroll">
              <Column header="№" :style="{width: '60px'}">
                <template #body="{index}">
                  {{index+1}}
                </template>
              </Column>

              <Column field="education_discipline_name" header="Дисциплина"></Column>
              <Column field="education_type_name" header="Тип"></Column>
              <Column field="room" header="Аудитория"></Column>
              <Column field="date" header="Дата опоздания"></Column>
              <Column field="late_time" header="Сколько времени опоздал"></Column>
              <Column field="reason" header="Причина"></Column>
            </DataTable>

          </div>
          <div v-else>Здесь пусто</div>
        </div>
      </div>
    </Dialog>
    <!-- end TeacherLateList -->


  </div>
</template>

<script>
  import {mapActions, mapState} from 'vuex'


  export default {
    name: 'FullSchedule',
    data() {
      return {
        loading: true,
        scheduleTable: {},
        newLate: {
          display: false,
          loading: false,
          form: {},
          name: '',
        },
        teacherLate: {
          display: false,
          list: [],
          name: '',
        },
      }
    },
    computed: {
      ...mapState('managerSchedule', ['days', 'scheduleIntervalTimes', 'rooms']),
    },
    methods: {
      ...mapActions('managerSchedule', ['GET_DAYS', 'GET_SCHEDULE_INTERVAL_TIMES', 'GET_SCHEDULE',
        'GET_TEACHER_LATE', 'POST_TEACHER_LATE', 'GET_ROOMS'
      ]),
      async getSchedule() {
        const schedule = await this.GET_SCHEDULE()

        const scheduleTable = {}
        for (let day of this.days) {
          scheduleTable[day.id] = {}
          for (let intervalTime of this.scheduleIntervalTimes) {
            scheduleTable[day.id][intervalTime.id] = {}

            for (let room of this.rooms) {
              scheduleTable[day.id][intervalTime.id][room.id] = schedule
                .filter(i => i.day_id == day.id)
                .filter(i => i.schedule_interval_time_id == intervalTime.id)
                .filter(i => i.room_id == room.id)
            }
          }
        }

        this.scheduleTable = scheduleTable
      },
      openNewLate(id, day_id, schedule_interval_time_id, room_id) {
        const schedule = this.scheduleTable[day_id][schedule_interval_time_id][room_id].find(i => i.id === id)

        if (schedule) {
          this.newLate.name = `${schedule.education_discipline_name} - ${schedule.lastname} ${schedule.firstname}`

          this.newLate.form = {
            schedule_id: schedule.id,
            date: null,
            late_time: '',
            reason: '',
          }

          this.newLate.display = true
        }
      },
      closeNewLate() {
        this.newLate.display = false
      },
      async postTeacherLate() {
        this.newLate.loading = true

        const res = await this.POST_TEACHER_LATE(this.newLate.form)
        if (res) {
          this.closeNewLate()
          this.$message({title: 'Сохранено'})
        }
        this.newLate.loading = false
      },


      async openTeacherLateList(id, day_id, schedule_interval_time_id, room_id) {
        const schedule = this.scheduleTable[day_id][schedule_interval_time_id][room_id].find(i => i.id === id)

        if (schedule) {
          this.teacherLate.name = `${schedule.lastname} ${schedule.firstname}`

          this.teacherLate.list = await this.GET_TEACHER_LATE(schedule.user_id)
          this.teacherLate.display = true
        }
      },
    },
    async mounted() {

      await this.GET_DAYS()
      await this.GET_SCHEDULE_INTERVAL_TIMES()

      await this.GET_ROOMS()


      await this.getSchedule()

      this.loading = false
    },
  }

</script>

<style scoped>
  .w150 {
    width: 150px;
  }

  .min-w120 {
    min-width: 120px;
  }

  .min-w300 {
    min-width: 300px;
  }


  .w100p {
    width: 100%;
  }

  .text-violet {
    color: #444791;
  }

  .bg-violet {
    background-color: #e8ebfa;
  }

  .border-left-violet {
    border-left: 3px solid #5b5fc7;
  }


  .table-scroll {
    overflow-x: scroll;
  }

  .table-scroll table th:first-child {
    position: sticky;
    left: 0;
    background-color: #f0f0f0;
  }

</style>


